import React from 'react'

import RoShop from './assets/RoShop_long.png'
import hero_bg from "./assets/hero_bg.jpg"
import { features, features_heading, features_subheading } from './config/features.config'

function App() {
  return (
    <div>        
        <div className="w-full">
            <div className='bg-white w-full fixed z-30'>
                <div className="container bg-white flex flex-row justify-between mx-auto my-2 px-4">
                    <div className="flex flex-row">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="align-middle">
                                        <div id="header_logo">
                                            <p className="text-2xl md:text-3xl font-black"><span className="" style={{color: 'rgb(87, 105, 171)'}}>Ro</span>Shop</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        
        <div className="bg-black bg-no-repeat bg-cover" style={{ backgroundImage: `url(${hero_bg})`}}>
            <div className="mx-auto pt-52 pb-56 text-center container px-4">
                <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl text-white">SELL YOUR ROBLOX ASSETS SAFE AND EASY</p>
                <p className="text-lg mt-4 text-white">you create your products, we take care of the rest.</p>
                
                <div className="bg-accent rounded-3xl w-fit mx-auto mt-10">
                    <p className='text-lg font-medium text-white py-2 px-6'>                    
                        COMING SOON
                    </p>
                </div>
            </div>
        </div>

        <div className=''>
            <div className="pt-16 bg-slate-100">
                <div className="container mx-auto pt-40 px-4">
                    <p className="text-2xl font-extrabold text-center">{features_heading}</p>
                    <p className="text-center w-3/4 md:w-1/2 lg:w-2/5 mx-auto py-3">{features_subheading}</p>
                    
                    <div className="pt-10 pb-44 grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                        {features.map((feature, key) => {
                        return (
                            <div key={key} className="bg-white rounded-xl">
                                <div className="bg-accent/20 h-full rounded-xl">
                                    <div className="ml-3 pt-2">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className='align-middle'>
                                                        <svg className='mr-2' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" dangerouslySetInnerHTML={{__html: feature.svg_icon}}></svg>
                                                    </td>
                                                    <td className='align-middle'>                                
                                                        <p className="text-xl font-semibold">{feature.feature_heading}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>                                
                                    </div>
                                    
                                    <p className="ml-3 pb-6 pr-2">{feature.feature_description}</p>
                                </div>
                            </div>
                        )
                        })}
                    </div>
                </div>
            </div>    

            {/* End wave */}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#f1f5f9" fillOpacity="1" d="M0,160L48,181.3C96,203,192,245,288,245.3C384,245,480,203,576,165.3C672,128,768,96,864,96C960,96,1056,128,1152,149.3C1248,171,1344,181,1392,186.7L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
            </svg>
        </div>


        <div className=''>
            <div className="bg-white">
                <div className="container mx-auto pt-40 px-4">
                    <p className="text-2xl font-extrabold text-center">COMING SOON</p>
                    <a href="https://discord.com/invite/YzJzSfDjzf">
                        <p className="text-center w-3/4 md:w-1/2 lg:w-1/3 mx-auto py-3">Join the discord to find more information about the release.</p>                    
                    </a>
                </div>
            </div>    
        </div>

        <div className="">
            {/* wave */}
            <svg className='mt-56' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 255">
                <path fill="#f1f5f9" fillOpacity="1" d="M0,160L48,181.3C96,203,192,245,288,245.3C384,245,480,203,576,165.3C672,128,768,96,864,96C960,96,1056,128,1152,149.3C1248,171,1344,181,1392,186.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
            </svg>

            {/* footer */}
            <div className="bg-slate-100 w-full min-h-[400px]">
                <div id="header_logo" className='text-center p-5'>
                    <p className="text-2xl md:text-3xl font-black"><span  style={{color: 'rgb(87, 105, 171)'}}>Ro</span>Shop</p>
                
                    <div className="w-4/5 mx-auto">
                        <hr className='my-10 border-accent/5 border-2' />
                    </div>

                    <div className="md:grid md:grid-cols-3">
                            <a href="https://docs.google.com/document/d/1oq10rwTrF76Mt2kHXJAGeBOw567PBVrCnUC8GdJ-AV8/edit?usp=sharing">
                                <p className="text-black text-center">Careers</p>
                            </a>
                            <a href="mailto: info@roshop.dev">
                                <p className="text-black text-center">Email</p>
                            </a>
                            <a href="https://discord.gg/YzJzSfDjzf" target="_blank" rel="noreferrer">
                                <p className="text-black text-center">Discord</p>
                            </a>
                    </div>

                    <div className="w-2/5 mx-auto">
                        <hr className='my-10 border-accent/5 border-2' />
                    </div>

                    <p className="text-lg font-black"><span style={{color: 'rgb(87, 105, 171)'}}>Ro</span>Shop <span className='text-base font-normal'>is not affiliated with the Roblox Corporation</span></p>
                </div>
            </div>        
        </div>
    </div>
  )
}

export default App