import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCGmZcLWd9XxHg7W7Oa0QERDOkrECVb-wk",
  authDomain: "roshop-landing.firebaseapp.com",
  projectId: "roshop-landing",
  storageBucket: "roshop-landing.appspot.com",
  messagingSenderId: "206949801618",
  appId: "1:206949801618:web:23d73853c671a0f45f904b",
  measurementId: "G-S3PVNML0JC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);